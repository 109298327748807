<template>
  <div class="account-pages my-5">
    <div class="container">
      <div class="justify-content-center auth-container">
        <div class="card">
          <div class="card-body p-0">
            <div>
              <div v-if="config" class="mx-auto mb-5 login-header py-3">
                <a routerLink="/" t>
                  <img
                      src="@assets/images/logo.png"
                      style="display:block;margin:auto;height:60px;max-width:100%;"
                  />
                </a>
              </div>
              <div class="pr-5 pl-5 pb-5">
                <p class="text-muted mt-1 mb-4 text-center">
                  <span v-if="validationMessage === ''">
                    <b-spinner small /> Validating
                  </span>
                  <span v-else>
                    {{validationMessage}}
                  </span>
                </p>
                <div v-if="validationSuccessful" class="text-center mt-4">
                  <router-link :to="{ name: 'agency.dashboard' }" class="btn btn-outline-primary"
                  >Go to Dashboard Now</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      validationSuccessful:false,
      validationMessage:''
    }
  },

  computed: {
    config() {
      return this.$store.getters['app/config']
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  mounted() {
    this.checkValidation();
  },
  methods: {
    checkValidation() {
      this.$store
        .dispatch('auth/validateEmail', this.$route.params.validation_key)
        .then((resp) => {
          this.validationSuccessful = resp.data.success;
          this.validationMessage = resp.data.message;
          if(this.validationSuccessful){
            this.$store
                .dispatch('auth/getProfile')
                .then((user) => {
                  this.$router.push('/' + user.role.toLocaleLowerCase())
                  this.loadingImpersonate = false
                })
                .catch(() => {
                  this.loadingImpersonate = false
                })
          }
        })
        .catch((resp) => {
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-header{
  background: #284373;
}
.auth-container {
  width: 460px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 15vh;
  .custom-control-label {
    &:before,
    &:after {
      width: 20px;
      height: 20px;
      top: 0px;
    }
  }

  .input-group-text {
    padding: 5px 10px;
  }
}
</style>
